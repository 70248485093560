<template>
  <div class="repositorioUnico">
      <!-- CONTENIDO -->
    <div class="container">
      <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
      <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Repositorio Único de Sitios Públicos conectados y por conectar</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom:30px;">Repositorio Único de Sitios Públicos conectados y por conectar</h1>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <p class="text-justify">Bienvenidas y bienvenidos al <strong>Repositorio Único de Sitios Públicos conectados y por conectar</strong>.</p>
          <p class="text-justify">Es un sistema informático que facilitará el registro, validación y actualización de la información de los sitios públicos que requieran conectividad a Internet, con el objetivo que sea consultada operadores de
            telecomunicaciones,
            especialmente, por la empresa productiva del Estado “CFE Telecomunicaciones e Internet para todos” para que tomen en consideración los sitios registrados para el despliegue de la oferta de sus servicios.</p>
          <p class="text-justify">La información que se registre en el <strong>Repositorio Único</strong>, también, podrá ser consultada por otras partes interesadas, como datos abiertos.</p>
          <button class="btn btn-default btn-sm xsmall-top-buffer" type="button" onClick="window.location.href = 'https://ru.sct.gob.mx/';">IR AL SISTEMA</button>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/sitios_publicos.ods" download="sitios_publicos.ods">CONSULTAR DATOS ABIERTOS</a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href="/">Regresar</a>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import router from "../router"
export default {
  name: 'repositorioUnico',
   methods: {
    back(){
        router.replace('/')
    },
  },
}
</script>

